































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";

export default mixins(MyMixin).extend({
  name: "ws-logo-header",
  props: {
    dark: {
      type: Boolean,
    },
    transparent: {
      type: Boolean,
    },
  },
});
