const plansMixins = {
    data() {
        return {
            customPlanFeatures: ['Storage', 'Pick & Pack', 'Labeling', 'Inventory & Reporting', 'Handling in and out', 'Access To Customer Portal', 'Customer Support', 'Last-mile delivery across UAE', 'Cash On Delivery', 'Returns', 'Customized Packaging', 'Kitting', 'Product Inbound'],
            plans: [
                {
                    id: 1,
                    slug: 'starter',
                    name: 'Starter',
                    price: 499,
                    items: [
                        {text: 'Storage', value: '5 CBM across UAE', icon: 'storage.png'},
                        {text: 'Pick & Pack', value: 'Up to 60 Orders', icon: 'packing.png'},
                        {text: 'Labeling', value: 'Included', icon: 'labeling.png'},
                        {text: 'Inventory Management', value: 'Included', icon: 'inventory.png'},
                    ],
                },
                {
                    id: 2,
                    slug: 'advanced',
                    name: 'Advanced',
                    price: 1199,
                    items: [
                        {text: 'Storage', value: '10 CBM across UAE', icon: 'storage.png'},
                        {text: 'Pick & Pack', value: 'Up to 200 Orders', icon: 'packing.png'},
                        {text: 'Labeling', value: 'Included', icon: 'labeling.png'},
                        {text: 'Inventory Management', value: 'Included', icon: 'inventory.png'},
                    ],
                },
                {
                    id: 3,
                    slug: 'premium',
                    name: 'Premium',
                    price: 2699,
                    items: [
                        {text: 'Storage', value: '20 CBM across UAE', icon: 'storage.png'},
                        {text: 'Pick & Pack', value: 'Up to 500 Orders', icon: 'packing.png'},
                        {text: 'Labeling', value: 'Included', icon: 'labeling.png'},
                        {text: 'Inventory Management', value: 'Included', icon: 'inventory.png'},
                    ],
                },
            ],
            extra: [
                {text: 'Delivery & Returns', value: '+ AED 15/order', icon: 'delivery.png'},
                {text: 'Cash On Delivery', value: '+ AED 5/order', icon: 'cash.png'},
            ],
        }
    }
}

export default plansMixins;