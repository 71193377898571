import Westore from '@/services/westore';

export interface PlanEntry {
    type: Number;
    sub_type?: Number;
    name: String;
    email: String;
    phone: String;
}

export class PlanRequest {
    static uri: string = 'contact-us';
    static send(data: PlanEntry){
        return Westore.create(data, PlanRequest.uri);
    };
}
